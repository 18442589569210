.sidebarContainer {
  display: flex;
  flex-direction: row;
  z-index: 1;
}
.sidebarFixed {
  position: fixed;
}
.sidebar {
  width: 300px;
  min-width: 300px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
  font-size: 1rem;
}
.hidden {
  display: none;
}

.sidebarToggler {
  width: 50px;
  min-width: 50px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;

  color: #fff;
}
.sidebarToggler img {
  filter: invert(100%);
}

.togglerSymbol {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar-content {
  /*stört Darstellung auf kleinen Screens*/
  /*position: fixed;*/
  width: inherit;
}
.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .links {
  margin-top: 0px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links a {
  display: flex;
  padding: 5px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.sidebar .links img {
  margin-right: 10px;
  filter: invert(100%);
}
.sidebar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
  filter: invert(40%);
}

/* avatar in sidebar */
.sidebar .user .avatar {
  margin-bottom: 10px;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
}

/* avatar in sidebar */
.ivk_vwltg {
  width: auto;
  height: 1.2rem;
}

.sidebar_icon_nutzer {
  width: 2rem;
  height: auto;
  margin: 0;
}

.sidebar_icon_house {
  width: 1.6rem;
  height: auto;
  padding-right: 0.5rem;
}

.sidebar_border {
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
}
