.monatsptk_container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  gap: 10px;
  min-width: 13rem;
}

.monatsptk_row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  max-height: 5rem; /* Adjust the max-height value as per your preference */
  overflow-y: hidden;
  gap: 10px;
}

.monatsptk_header {
  font-weight: bold;
  background-color: #f2f2f2;
}
.monatsptk_titel {
  flex-basis: 40%;
  font-size: 0.8rem;
  min-width: 20rem;
}

.monatsptk_row {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.monatsptk_date,
.monatsptk_werte {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
  min-width: 5rem;
  font-size: 0.8rem;
}
.monatsptk_date {
  flex-basis: 20%;
}
.monatsptk_werte {
  flex-basis: 15%;
}

.monatsptk_note {
  flex-basis: 50%;
  position: relative;
}

.monatsptk_note textarea {
  width: 100%;
  min-height: 100px; /* Adjust the minimum height as needed */
  resize: vertical;
  min-width: 7rem;
  font-size: 0.8rem;
}

.monatsptk_title_container {
  display: flex;
  font-weight: bold;
  gap: 10px;
  justify-content: stretch;
}

.monatsPtkBNRLogo {
  width: 6rem;
}
