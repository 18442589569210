@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #5acb6f;
  --highlight-color: #d13267;
  --bg-color: #f4f4f4;
}

/* base styles */
html {
  min-height: 100%; /* Look, it's not fixed anymore! */

  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
}
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}
ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 2em;
  color: var(--heading-color);
  display: inline-block;
}
.section-title {
  font-size: 1.3em;
  color: var(--heading-color);
  display: inline-block;
  margin-bottom: 1.2rem;
}

.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.App {
  display: flex;
}
.App .container2 {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 60px;
  margin-top: 45px;
}

.avatar {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
}

.project-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
.konvBlock {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}
.flexHeaderKonvBlock {
  display: flex;
  color: red !important;
  justify-content: space-between;
}

.project-list h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.project-list p {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-list .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.project-list ul {
  margin: 10px 0;
  display: flex;
}
.project-list li {
  margin-right: 10px;
}
.project-list .avatar {
  width: 30px;
  height: 30px;
}

.bell-box {
  display: flex;
}
.bell-icon {
  width: 20px;
}

.project-filter {
  margin: 30px auto;
}
.project-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.project-filter p {
  font-size: 0.9em;
  margin-right: 10px;
  margin-top: 4px;
}
.project-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}
.project-filter button:last-child {
  border: 0;
}
.project-filter button.active {
  color: var(--primary-color);
}

.prodomo_nav {
  margin-top: 5rem;
}

.vz_backlink {
  margin-top: 1.4rem;
  margin-bottom: 1.6rem;
}

.pikto_cont_link {
  display: flex;
  justify-content: left;
}

.klein_pikto_container {
  width: 5rem;
  display: flex;

  flex-grow: 0;
  justify-content: space-between;
}

.CommunityRL_Fries {
  display: flex;
  margin-top: 2rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.fries_container {
  width: 5rem;
  background-image: url(/static/media/WebFriesRahmen.8b63b153.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 20%;
  padding: 2.5rem;
  justify-self: center;
}

.FriesLabel {
  width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.8rem;
  text-align: center;
}
.CommunityRL_Fries img {
  width: 3rem;
  height: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.kontakt {
  margin-bottom: 1.5rem;
}
/*
.fries_container span::before {
  content: "d ";
  position: absolute;
  top: 6%;
  left: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(-45deg);
}
.fries_container span::after {
  content: "d ";
  position: absolute;
  top: 6%;
  right: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(45deg);
}
.fries_container::after {
  content: "d ";
  position: absolute;
  bottom: 6%;
  left: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(45deg);
}
.fries_container::before {
  content: "d ";
  position: absolute;
  bottom: 6%;
  right: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(-45deg);
}
*/
.CommunityRL_table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}
th,
td {
  background-color: #eee;
  text-align: left;
  vertical-align: top;
}
.CommunityRL_table {
  margin-bottom: 1.4rem;
}
.hinweis {
  max-width: 40rem;
}
.hinweis p {
  margin-bottom: 3rem;
}
.hinweis p:first-child {
  margin-bottom: 2rem;
}

.user-list {
  width: 250px;
  min-width: 250px;
  padding: 30px;
  box-sizing: border-box;
  background: #fbfbfb;
  color: var(--heading-color);
}
.user-list h2 {
  text-align: right;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-size: 1.2em;
}
.user-list .user-list-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px auto;
}
.user-list .avatar {
  margin-left: 10px;
  width: 40px;
  height: 40px;
}

/* online users */
.user-list .online-user {
  display: inline-block;
  margin-right: 10px;
  width: 12px;
  height: 12px;
  background: #0ebb50;
  border-radius: 50%;
  margin-top: 2px;
}
.create-form {
  max-width: 600px;
}

.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.project-details {
  display: flex;
  flex-wrap: wrap;
  /*grid-template-columns: 3fr 2fr;
  align-items: start;*/
  grid-gap: 60px;
}

/* project summary */
.project-summary {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  min-width: 400px;
}
.project-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.project-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.project-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.project-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}

/* project comments */
.project-comments {
  min-width: 400px;
}

.project-comments label {
  margin-bottom: 0px;
}
.project-comments textarea {
  min-height: 12em;
  font-size: 1.2em;
}

/* comment list */
.project-comments h4 {
  color: var(--heading-color);
}
.project-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}
.comment-author {
  display: flex;
  align-items: center;
  color: var(--title-color);
}
.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comment-date {
  color: var(--text-color);
  font-size: 0.9em;
  margin: 4px 0 10px;
}
.comment-content {
  color: var(--text-color);
  font-size: 0.9em;
}

/* comments scrollbar */
.project-comments {
  max-height: 800px;
  overflow: auto;
  padding-right: 20px;
  /* firefox scrollbar style */
  scrollbar-width: 20px;
  scrollbar-color: #fff #e4e4e4;
}
/* Chrome, Edge, and Safari scrollbar styles */
.project-comments::-webkit-scrollbar {
  width: 6px;
}
.project-comments::-webkit-scrollbar-track {
  background: #fff;
}
.project-comments::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid #e4e4e4;
}

.calculation-details {
  display: flex;
  flex-wrap: wrap;
  /*grid-template-columns: 3fr 2fr;
  align-items: start;*/
  grid-gap: 60px;
}

/* project summary */
.calculation-summary {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  min-width: 400px;
}
.calculation-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.calculation-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.calculation-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.calculation-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.calculation-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}

.logoText {
  font-weight: bold;
  color: #fff;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.logoContainer {
  display: flex;
  flex-grow: 0.6;
}

.logoContainer img {
  margin-right: 10px;
  /*filter: invert(25%);*/
  width: 36px;
  margin-top: -8px;
}
.sponsor {
  align-self: center;
}

.top-nav {
  z-index: 2;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: var(--primary-color);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  height: 1.3rem;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  cursor: pointer;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 700px) {
  .menu-button-container {
    display: flex;
    align-self: flex-end;
  }
  .menu {
    position: absolute;
    top: 4px;
    margin-top: 31px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 3.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: var(--primary-color);
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
  z-index: 1;
}
.sidebarFixed {
  position: fixed;
}
.sidebar {
  width: 300px;
  min-width: 300px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
  font-size: 1rem;
}
.hidden {
  display: none;
}

.sidebarToggler {
  width: 50px;
  min-width: 50px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;

  color: #fff;
}
.sidebarToggler img {
  filter: invert(100%);
}

.togglerSymbol {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar-content {
  /*stört Darstellung auf kleinen Screens*/
  /*position: fixed;*/
  width: inherit;
}
.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .links {
  margin-top: 0px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links a {
  display: flex;
  padding: 5px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.sidebar .links img {
  margin-right: 10px;
  filter: invert(100%);
}
.sidebar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
  filter: invert(40%);
}

/* avatar in sidebar */
.sidebar .user .avatar {
  margin-bottom: 10px;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
}

/* avatar in sidebar */
.ivk_vwltg {
  width: auto;
  height: 1.2rem;
}

.sidebar_icon_nutzer {
  width: 2rem;
  height: auto;
  margin: 0;
}

.sidebar_icon_house {
  width: 1.6rem;
  height: auto;
  padding-right: 0.5rem;
}

.sidebar_border {
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
}

.prodomo_nav {
  margin-top: 5rem;
}

.vz_backlink {
  margin-top: 1.4rem;
  margin-bottom: 1.6rem;
}

.pikto_cont_link {
  display: flex;
  justify-content: left;
}

.klein_pikto_container {
  width: 5rem;
  display: flex;

  flex-grow: 0;
  justify-content: space-between;
}

.CommunityRL_Fries {
  display: flex;
  margin-top: 2rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.fries_container {
  width: 5rem;
  background-image: url(/static/media/WebFriesRahmen.8b63b153.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 20%;
  padding: 2.5rem;
  justify-self: center;
}

.FriesLabel {
  width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.8rem;
  text-align: center;
}
.CommunityRL_Fries img {
  width: 3rem;
  height: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.kontakt {
  margin-bottom: 1.5rem;
}
/*
.fries_container span::before {
  content: "d ";
  position: absolute;
  top: 6%;
  left: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(-45deg);
}
.fries_container span::after {
  content: "d ";
  position: absolute;
  top: 6%;
  right: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(45deg);
}
.fries_container::after {
  content: "d ";
  position: absolute;
  bottom: 6%;
  left: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(45deg);
}
.fries_container::before {
  content: "d ";
  position: absolute;
  bottom: 6%;
  right: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(-45deg);
}
*/
.CommunityRL_table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}
th,
td {
  background-color: #eee;
  text-align: left;
  vertical-align: top;
}
.CommunityRL_table {
  margin-bottom: 1.4rem;
}
.hinweis {
  max-width: 40rem;
}
.hinweis p {
  margin-bottom: 3rem;
}
.hinweis p:first-child {
  margin-bottom: 2rem;
}

.NeueKalkulationVerzeichnis {
  margin-top: 3rem;
}

.prodomo_nav {
  margin-top: 5rem;
}

.vz_backlink {
  margin-top: 1.4rem;
  margin-bottom: 1.6rem;
}

.pikto_cont_link {
  display: flex;
  justify-content: left;
}

.klein_pikto_container {
  width: 5rem;
  display: flex;

  flex-grow: 0;
  justify-content: space-between;
}

.CommunityRL_Fries {
  display: flex;
  margin-top: 2rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.fries_container {
  width: 10rem;
  background-image: url(/static/media/WebFriesRahmen.8b63b153.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 20%;
  padding: 2.5rem;
  justify-self: center;
}

.FriesLabel {
  width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  text-align: center;
}
.CommunityRL_Fries img {
  width: 4.5rem;
  height: 4.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.kontakt {
  margin-bottom: 1.5rem;
}
/*
  .fries_container span::before {
    content: "d ";
    position: absolute;
    top: 6%;
    left: -85%;
  
    text-transform: uppercase;
    font-size: 0.3rem;
    font-weight: 700;
    color: #555;
    background-color: #555;
    padding: 1rem 4rem;
    transform: rotate(-45deg);
  }
  .fries_container span::after {
    content: "d ";
    position: absolute;
    top: 6%;
    right: -85%;
  
    text-transform: uppercase;
    font-size: 0.3rem;
    font-weight: 700;
    color: #555;
    background-color: #555;
    padding: 1rem 4rem;
    transform: rotate(45deg);
  }
  .fries_container::after {
    content: "d ";
    position: absolute;
    bottom: 6%;
    left: -85%;
  
    text-transform: uppercase;
    font-size: 0.3rem;
    font-weight: 700;
    color: #555;
    background-color: #555;
    padding: 1rem 4rem;
    transform: rotate(45deg);
  }
  .fries_container::before {
    content: "d ";
    position: absolute;
    bottom: 6%;
    right: -85%;
  
    text-transform: uppercase;
    font-size: 0.3rem;
    font-weight: 700;
    color: #555;
    background-color: #555;
    padding: 1rem 4rem;
    transform: rotate(-45deg);
  }
  */
.CommunityRL_table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}
th,
td {
  background-color: #eee;
  text-align: left;
  vertical-align: top;
}
.CommunityRL_table {
  margin-bottom: 1.4rem;
}
.hinweis {
  max-width: 40rem;
}
.hinweis p {
  margin-bottom: 3rem;
}
.hinweis p:first-child {
  margin-bottom: 2rem;
}

.monatsptk_container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  min-width: 13rem;
}

.monatsptk_row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  max-height: 5rem; /* Adjust the max-height value as per your preference */
  overflow-y: hidden;
  grid-gap: 10px;
  gap: 10px;
}

.monatsptk_header {
  font-weight: bold;
  background-color: #f2f2f2;
}
.monatsptk_titel {
  flex-basis: 40%;
  font-size: 0.8rem;
  min-width: 20rem;
}

.monatsptk_row {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.monatsptk_date,
.monatsptk_werte {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
  min-width: 5rem;
  font-size: 0.8rem;
}
.monatsptk_date {
  flex-basis: 20%;
}
.monatsptk_werte {
  flex-basis: 15%;
}

.monatsptk_note {
  flex-basis: 50%;
  position: relative;
}

.monatsptk_note textarea {
  width: 100%;
  min-height: 100px; /* Adjust the minimum height as needed */
  resize: vertical;
  min-width: 7rem;
  font-size: 0.8rem;
}

.monatsptk_title_container {
  display: flex;
  font-weight: bold;
  grid-gap: 10px;
  gap: 10px;
  justify-content: stretch;
}

.nk-lohnzettel {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.result {
  margin-top: 10px;
}

.net-pay {
  font-weight: bold;
  color: #4caf50;
}

.nk-lohnzettel {
  max-width: 400px;
  margin-left: 0px;
  margin-top: 3rem;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.bruttoNettoAnmkg {
  max-width: 440px;
  margin-left: 0px;
}
.speicherTitel {
  margin-top: 0 rem;
  margin-bottom: 3rem;
}
.kalk_speichern {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.result {
  margin-top: 10px;
}

.net-pay {
  font-weight: bold;
  color: #4caf50;
}

.calculation-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}

.konvBlock {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}
.flexHeaderKonvBlock {
  display: flex;
  color: red !important;
  justify-content: space-between;
}

.calculation-list h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.calculation-list p {
  color: var(--text-color);
  font-size: 0.9em;
}
.calculation-list .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.calculation-list ul {
  margin: 10px 0;
  display: flex;
}
.calculation-list li {
  margin-right: 10px;
}
.calculation-list .avatar {
  width: 30px;
  height: 30px;
}

.bell-box {
  display: flex;
}
.bell-icon {
  width: 20px;
}

.calculation-filter {
  margin: 30px auto;
}
.calculation-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.calculation-filter p {
  font-size: 0.9em;
  margin-right: 10px;
  margin-top: 4px;
}
.calculation-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}
.calculation-filter button:last-child {
  border: 0;
}
.calculation-filter button.active {
  color: var(--primary-color);
}

.bnr_container {
  margin-top: 3rem;
}
.nk-lohnzettel {
  max-width: 400px;
  margin-left: 0px;
  margin-top: 3rem;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.bruttoNettoAnmkg {
  max-width: 440px;
  margin-left: 0px;
}
.speicherTitel {
  margin-top: 0 rem;
  margin-bottom: 3rem;
}
.kalk_speichern {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input_bnr {
  width: 15rem;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.result {
  margin-top: 10px;
}

.net-pay {
  font-weight: bold;
  color: #4caf50;
}

.nk-lohnzettel {
  max-width: 400px;
  margin-left: 0px;
  margin-top: 3rem;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.bruttoNettoAnmkg {
  max-width: 440px;
  margin-left: 0px;
}
.speicherTitel {
  margin-top: 0 rem;
  margin-bottom: 3rem;
}
.kalk_speichern {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input_bnr {
  width: 15rem;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.result {
  margin-top: 10px;
}

.net-pay {
  font-weight: bold;
  color: #4caf50;
}

.bnr_lead {
  color: #666;
  font-weight: bold;
}
.bnr_tooltip {
  font-size: 13px;
}

.bnr_help_float_right {
  float: right;
}
.bnr_helper_margin1 {
  margin-bottom: 0.9rem;
  margin-top: 0.1rem;
}

.bnr_nk-lohnzettel {
  max-width: 45rem;
  margin-left: 0px;
  margin-top: -2rem;
  padding: 20px;
  border: 3px solid #058785;
  border-radius: 5px;
  position: relative;

  width: 36rem;
  font-size: 1rem;
}
.bnr_nk_body {
  display: flex;
}

.bnr_dg_zuschläge {
  margin-bottom: 1rem;
}
.bnr_spalte1 {
  width: 11rem;
  border-left: 2px solid #ccc;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.bnr_spalte2 {
  width: 12rem;
  border-left: 2px solid #ccc;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.bnr_spalte3 {
  width: 15rem;
  border-left: 2px solid #ccc;

  border-right: 2px solid #ccc;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.bnr_bruttoNettoAnmkg {
  max-width: 440px;
  margin-left: 0px;
}
.bnr_speicherTitel {
  margin-top: 0 rem;
  margin-bottom: 3rem;
}
.bnr_kalk_speichern {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.bnr_title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #058785;
}

.bnr_form-field {
  margin-bottom: 0px;
}

.bnr_label {
  display: block;
  margin: 0;
  box-sizing: content-box;
}

.bnr_input {
  padding: 0.4rem;
  border: 0.13rem solid #ccc;
  border-radius: 0.25rem;
  width: 6rem;
  height: 1.1rem;
  box-sizing: content-box;
}

.bnr_select {
  margin: 2rem;
}
.bnr_inputField {
  padding-top: 0.22rem;
  padding-bottom: 0.22rem;
}
.bnr_label {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.bnr_result {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.bnr_net-pay {
  font-weight: bold;
  color: #0f7513;
}

.bnr_net-cost {
  font-weight: bold;
  color: #161674;
}

bnr_body {
  margin: 0rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bnr_bnrLogo {
  position: absolute;
  width: 2.5rem;
  left: 33rem;
}

.bnr_trc1 {
  background-color: #e2e2e2;
}
.bnr_trc2 {
  background-color: #f9f9f9;
}

bnr_p {
  display: block;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.bnr_label span {
  display: inline;
}

.calculation-details {
  display: flex;
  flex-wrap: wrap;
  /*grid-template-columns: 3fr 2fr;
    align-items: start;*/
  grid-gap: 60px;
}

/* project summary */
.calculation-summary {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  min-width: 400px;
}
.calculation-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.calculation-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.calculation-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.calculation-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.calculation-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}
.add-comment textarea {
  max-width: 25rem;
}

.monatsptk_container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  min-width: 13rem;
}

.monatsptk_row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  max-height: 5rem; /* Adjust the max-height value as per your preference */
  overflow-y: hidden;
  grid-gap: 10px;
  gap: 10px;
}

.monatsptk_header {
  font-weight: bold;
  background-color: #f2f2f2;
}
.monatsptk_titel {
  flex-basis: 40%;
  font-size: 0.8rem;
  min-width: 20rem;
}

.monatsptk_row {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.monatsptk_date,
.monatsptk_werte {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
  min-width: 5rem;
  font-size: 0.8rem;
}
.monatsptk_date {
  flex-basis: 20%;
}
.monatsptk_werte {
  flex-basis: 15%;
}

.monatsptk_note {
  flex-basis: 50%;
  position: relative;
}

.monatsptk_note textarea {
  width: 100%;
  min-height: 100px; /* Adjust the minimum height as needed */
  resize: vertical;
  min-width: 7rem;
  font-size: 0.8rem;
}

.monatsptk_title_container {
  display: flex;
  font-weight: bold;
  grid-gap: 10px;
  gap: 10px;
  justify-content: stretch;
}

.monatsPtkBNRLogo {
  width: 6rem;
}

.report_container {
  margin-top: 3rem;
}
.report_header {
  font-weight: bold;
}

table td {
  width: 200px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input_bnr {
  width: 15rem;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.result {
  margin-top: 10px;
}

