.bnr_lead {
  color: #666;
  font-weight: bold;
}
.bnr_tooltip {
  font-size: 13px;
}

.bnr_help_float_right {
  float: right;
}
.bnr_helper_margin1 {
  margin-bottom: 0.9rem;
  margin-top: 0.1rem;
}

.bnr_nk-lohnzettel {
  max-width: 45rem;
  margin-left: 0px;
  margin-top: -2rem;
  padding: 20px;
  border: 3px solid #058785;
  border-radius: 5px;
  position: relative;

  width: 36rem;
  font-size: 1rem;
}
.bnr_nk_body {
  display: flex;
}

.bnr_dg_zuschläge {
  margin-bottom: 1rem;
}
.bnr_spalte1 {
  width: 11rem;
  border-left: 2px solid #ccc;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.bnr_spalte2 {
  width: 12rem;
  border-left: 2px solid #ccc;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.bnr_spalte3 {
  width: 15rem;
  border-left: 2px solid #ccc;

  border-right: 2px solid #ccc;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.bnr_bruttoNettoAnmkg {
  max-width: 440px;
  margin-left: 0px;
}
.bnr_speicherTitel {
  margin-top: 0 rem;
  margin-bottom: 3rem;
}
.bnr_kalk_speichern {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.bnr_title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #058785;
}

.bnr_form-field {
  margin-bottom: 0px;
}

.bnr_label {
  display: block;
  margin: 0;
  box-sizing: content-box;
}

.bnr_input {
  padding: 0.4rem;
  border: 0.13rem solid #ccc;
  border-radius: 0.25rem;
  width: 6rem;
  height: 1.1rem;
  box-sizing: content-box;
}

.bnr_select {
  margin: 2rem;
}
.bnr_inputField {
  padding-top: 0.22rem;
  padding-bottom: 0.22rem;
}
.bnr_label {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.bnr_result {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.bnr_net-pay {
  font-weight: bold;
  color: #0f7513;
}

.bnr_net-cost {
  font-weight: bold;
  color: #161674;
}

bnr_body {
  margin: 0rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bnr_bnrLogo {
  position: absolute;
  width: 2.5rem;
  left: 33rem;
}

.bnr_trc1 {
  background-color: #e2e2e2;
}
.bnr_trc2 {
  background-color: #f9f9f9;
}

bnr_p {
  display: block;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.bnr_label span {
  display: inline;
}
