.calculation-details {
  display: flex;
  flex-wrap: wrap;
  /*grid-template-columns: 3fr 2fr;
    align-items: start;*/
  grid-gap: 60px;
}

/* project summary */
.calculation-summary {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  min-width: 400px;
}
.calculation-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.calculation-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.calculation-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.calculation-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.calculation-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}
.add-comment textarea {
  max-width: 25rem;
}
