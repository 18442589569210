.report_container {
  margin-top: 3rem;
}
.report_header {
  font-weight: bold;
}

table td {
  width: 200px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input_bnr {
  width: 15rem;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.result {
  margin-top: 10px;
}
