.bell-box {
  display: flex;
}
.bell-icon {
  width: 20px;
}

.calculation-filter {
  margin: 30px auto;
}
.calculation-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.calculation-filter p {
  font-size: 0.9em;
  margin-right: 10px;
  margin-top: 4px;
}
.calculation-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}
.calculation-filter button:last-child {
  border: 0;
}
.calculation-filter button.active {
  color: var(--primary-color);
}
