.prodomo_nav {
  margin-top: 5rem;
}

.vz_backlink {
  margin-top: 1.4rem;
  margin-bottom: 1.6rem;
}

.pikto_cont_link {
  display: flex;
  justify-content: left;
}

.klein_pikto_container {
  width: 5rem;
  display: flex;

  flex-grow: 0;
  justify-content: space-between;
}

.CommunityRL_Fries {
  display: flex;
  margin-top: 2rem;
  gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.fries_container {
  width: 5rem;
  background-image: url(../../assets/Piktogramme_allgemein/WebFriesRahmen.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 20%;
  padding: 2.5rem;
  justify-self: center;
}

.FriesLabel {
  width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.8rem;
  text-align: center;
}
.CommunityRL_Fries img {
  width: 3rem;
  height: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.kontakt {
  margin-bottom: 1.5rem;
}
/*
.fries_container span::before {
  content: "d ";
  position: absolute;
  top: 6%;
  left: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(-45deg);
}
.fries_container span::after {
  content: "d ";
  position: absolute;
  top: 6%;
  right: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(45deg);
}
.fries_container::after {
  content: "d ";
  position: absolute;
  bottom: 6%;
  left: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(45deg);
}
.fries_container::before {
  content: "d ";
  position: absolute;
  bottom: 6%;
  right: -85%;

  text-transform: uppercase;
  font-size: 0.3rem;
  font-weight: 700;
  color: #555;
  background-color: #555;
  padding: 1rem 4rem;
  transform: rotate(-45deg);
}
*/
.CommunityRL_table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}
th,
td {
  background-color: #eee;
  text-align: left;
  vertical-align: top;
}
.CommunityRL_table {
  margin-bottom: 1.4rem;
}
.hinweis {
  max-width: 40rem;
}
.hinweis p {
  margin-bottom: 3rem;
}
.hinweis p:first-child {
  margin-bottom: 2rem;
}
