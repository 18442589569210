.bnr_container {
  margin-top: 3rem;
}
.nk-lohnzettel {
  max-width: 400px;
  margin-left: 0px;
  margin-top: 3rem;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.bruttoNettoAnmkg {
  max-width: 440px;
  margin-left: 0px;
}
.speicherTitel {
  margin-top: 0 rem;
  margin-bottom: 3rem;
}
.kalk_speichern {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input_bnr {
  width: 15rem;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.result {
  margin-top: 10px;
}

.net-pay {
  font-weight: bold;
  color: #4caf50;
}
